<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="pub-tt m15">
          <div class="tabs">
            <router-link to="/set-equip" class="lk">设备</router-link>
            <router-link to="" class="lk cur">绑定计量表</router-link>
          </div>
          <div class="dix"></div>
        </div>
        <div class="gauges-sec flex-1 scrollybg">
          <div class="flex flex-pack-justify">
            <div class="col">
              <div class="mod mod1">
                <h5 class="smtit">设备</h5>
                <div class="detail">
                  <el-form ref="form" :model="biaoForm">
                    <el-form-item label="请选择设备：">
                      <el-select
                        v-model="equimentId"
                        placeholder="选择设备"
                        @change="getBindMeters"
                      >
                        <el-option
                          v-for="item in equiments"
                          :key="item.value"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mod mod2">
                <h5 class="smtit">绑定的计量表</h5>
                <div class="detail bind">
                  <draggable
                    v-model="meterList"
                    :group="groupBind"
                    animation="300"
                    @add="onAdd"
                    @remove="onRemove"
                    class="drag-content"
                  >
                    <div
                      v-for="(meter, index) in meterList"
                      class="list"
                      :key="index"
                    >
                      <router-link to="" class="lk bgfull">{{
                        meter.gaugesName
                      }}</router-link>
                    </div>
                  </draggable>
                </div>
              </div>
              <div class="mod mod3">
                <h5 class="smtit">回收站</h5>
                <div class="detail">
                  <draggable :group="groupDelete" animation="300" :sort="true">
                    <div class="delete">
                      <draggable
                        :group="groupDelete"
                        animation="300"
                        :sort="true"
                        style="height: 100%; width: 100%"
                      ></draggable>
                      <!-- <img src="../assets/images/set_10.png" alt="" class="icon" /> -->
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mod mod4">
                <h5 class="smtit">计量表</h5>
                <div class="detail all">
                  <div class="mb20">
                    <el-form ref="form" :model="biaoForm2">
                      <el-form-item label="请选择表计类型：">
                        <el-select
                          v-model="biaoForm2.type"
                          placeholder="计量表"
                          @change="getNotBindmeters"
                        >
                          <el-option label="计量表" value="1"></el-option>
                          <el-option label="传感器" value="2"></el-option>
                          <el-option label="虚拟表" value="3"></el-option>
                          <el-option label="离线表" value="4"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="计量表列表：">
                        <el-input
                          placeholder="搜索"
                          v-model="biaoForm2.search"
                          class=""
                          @input="handleInput()"
                        >
                          <el-button
                            slot="append"
                            icon="el-icon-search"
                          ></el-button>
                        </el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                  <draggable
                    v-model="fillist"
                    :group="groupAll"
                    animation="300"
                  >
                    <div
                      v-for="(meter, key) in fillist"
                      class="list"
                      :key="key"
                    >
                      <router-link to="" class="lk bgfull">{{
                        meter.gaugesName
                      }}</router-link>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { getEquipList } from "@/utils/common";
import {
  getBindMeterList,
  getNotBindMeterList,
  delBindMeter,
  addBindMeter,
} from "@/api/equip";
import draggable from "vuedraggable";
export default {
  data() {
    return {
      removeFlag: false,
      equimentId: "",
      equiments: [],
      biaoForm: {
        type: "",
        equip: "",
      },
      biaoForm2: {
        type: "1",
        search: "",
      },
      chooseInput: "",
      meterList: [],
      bindMeterList: [],
      allMeterList: [],
      fillist: [],
      groupBind: {
        name: "itxst",
        pull: true, // 拖出
        put: true, // 拖入
      },
      groupAll: {
        name: "itxst",
        pull: true, // 拖出
        put: false, // 拖入
      },
      groupDelete: {
        name: "itxst",
        pull: false, // 拖出
        put: true, // 拖入
      },
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
    draggable,
  },
  onAdd() {
    //
    console.log("添加", this.meterList);
  },
  created() {
    this.equiments = getEquipList();
  },
  mounted() {},
  watch: {},
  methods: {
    //取两个数组的差值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    //绑定计量表
    onAdd(e) {
      let t = this.getArrDifference(this.meterList, this.bindMeterList);
      let param = {
        deviceId: this.equimentId,
        gaugesId: this.getArrDifference(this.meterList, this.bindMeterList)[0]
          .id,
        gaugesName: this.getArrDifference(this.meterList, this.bindMeterList)[0]
          .name,
        type: this.biaoForm2.type,
      };
      addBindMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindMeters();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //解绑计量表
    onRemove(e) {
      let t = this.getArrDifference(this.meterList, this.bindMeterList);
      let param = {
        ids: this.getArrDifference(this.meterList, this.bindMeterList)[0].id,
      };
      delBindMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindMeters();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //搜索
    handleInput() {
      this.fillist = [];
      for (let i = 0; i < this.allMeterList.length; i++) {
        if (this.allMeterList[i].gaugesName.includes(this.biaoForm2.search)) {
          this.fillist.push(this.allMeterList[i]);
        }
      }
    },
    //获取已绑定的表计列表
    getBindMeters() {
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.equimentId,
      };
      getBindMeterList(param).then((res) => {
        if (res.data.code == 0) {
          this.meterList = res.data.rows;
          this.bindMeterList = this.meterList;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
      this.getNotBindmeters();
    },
    //获取指定类型的未绑定表计列表
    getNotBindmeters() {
      if (this.equimentId == "") {
        this.$message.warning("请选择设备");
        return;
      }
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.equimentId,
        type: this.biaoForm2.type,
      };
      getNotBindMeterList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            res.data.rows[i].gaugesName = res.data.rows[i].name;
          }
          this.allMeterList = res.data.rows;
          this.fillist = this.allMeterList;
          this.biaoForm2.search = "";
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.gauges-sec {
  padding: 0 60px;
}

.choose {
  width: 260px;
  margin-bottom: 25px;
}

.col {
  width: 30%;
  max-width: 387px;
}

.mod {
  margin-bottom: 25px;
}

.mod .smtit {
  font-size: 18px;
  text-align: center;
  width: 340px;
  line-height: 44px;
  margin: 0 auto;
  height: 44px;
  overflow: hidden;
  padding-top: 2px;
}

.mod .detail {
  padding: 25px 35px;
}

.mod .detail.bind {
  height: 300px;
  overflow-y: auto;
}

.drag-content {
  height: 240px;
}

.mod .detail.all {
  height: 600px;
  overflow-y: auto;
}

.mod1 {
  min-height: 300px;
  background: url(../assets/images/set_01.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 .smtit {
  background: url(../assets/images/set_05.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 >>> .el-select {
  display: block;
}

.mod1 >>> .el-form-item__label {
  float: none;
  text-align: left;
}

.mod2 {
  min-height: 348px;
  background: url(../assets/images/set_02.png) no-repeat center;
  background-size: 100% 100%;
}

.mod2 .smtit {
  background: url(../assets/images/set_06.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 {
  min-height: 378px;
  background: url(../assets/images/set_03.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .smtit {
  background: url(../assets/images/set_07.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .delete {
  background: url(../assets/images/set_10.png) no-repeat center;
  background-size: 100% 100%;
  width: 330px;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod3 .delete .icon {
  width: 180px;
  background: url(../assets/images/set_10.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 {
  min-height: 658px;
  background: url(../assets/images/set_04.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 .smtit {
  background: url(../assets/images/set_08.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 >>> .el-input-group {
  background: rgba(26, 29, 50, 0.5);
  border: 1px solid #4a4f64;
  border-radius: 4px;
  overflow: hidden;
}

.mod4 >>> .el-input-group__append {
  background: transparent;
  border: none;
  color: #fff;
}

.mod4 >>> .el-input-group .el-input__inner {
  border: none !important;
  background: none !important;
}

.list .lk {
  display: flex;
  font-size: 16px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.list .lk:last-child {
  margin-bottom: 0;
}

.mod2 .list .lk {
  background-image: url(../assets/images/set_13.png);
}

.mod2 .list .lk:hover {
  color: #8e7ff6;
}

.mod4 .list .lk {
  background-image: url(../assets/images/set_12.png);
}

.mod4 .list .lk:hover {
  color: #71aef5;
}
</style>
